import React from "react";
import { Link } from "gatsby";
import { title, subtitle, homepageLink } from "./pageNotFound.module.scss";

const PageNotFound = () => (
    <>
        <h1 className={title}>404</h1>
        <h2 className={subtitle}>Page Not Found</h2>
        <Link to={'/'} className={homepageLink}>Homepage</Link>
    </>
);

export default PageNotFound;
