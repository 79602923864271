import * as React from "react"
import Layout from "../components/Layout";
import Section from "../components/Section";
import PageNotFound from "../components/PageNotFound";

const NotFoundPage = () => (
    <Layout>
        <Section sectionClass={'text-center'}>
            <PageNotFound/>
        </Section>
    </Layout>
);

export default NotFoundPage
